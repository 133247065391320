// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-angebote-js": () => import("./../../../src/pages/angebote.js" /* webpackChunkName: "component---src-pages-angebote-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-haftungsausschluss-js": () => import("./../../../src/pages/haftungsausschluss.js" /* webpackChunkName: "component---src-pages-haftungsausschluss-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-js": () => import("./../../../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-newsletter-abmeldung-js": () => import("./../../../src/pages/newsletter-abmeldung.js" /* webpackChunkName: "component---src-pages-newsletter-abmeldung-js" */),
  "component---src-pages-newsletter-anmeldung-js": () => import("./../../../src/pages/newsletter-anmeldung.js" /* webpackChunkName: "component---src-pages-newsletter-anmeldung-js" */),
  "component---src-pages-preise-js": () => import("./../../../src/pages/preise.js" /* webpackChunkName: "component---src-pages-preise-js" */),
  "component---src-pages-ueber-uns-js": () => import("./../../../src/pages/ueber-uns.js" /* webpackChunkName: "component---src-pages-ueber-uns-js" */),
  "component---src-templates-news-entry-js": () => import("./../../../src/templates/news-entry.js" /* webpackChunkName: "component---src-templates-news-entry-js" */),
  "component---src-templates-news-list-js": () => import("./../../../src/templates/news-list.js" /* webpackChunkName: "component---src-templates-news-list-js" */)
}

